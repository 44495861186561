
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import axios from 'axios';
import swal from 'sweetalert';


import { withRouter, Link } from 'react-router-dom';


// users
import user4 from '../assets/images/users/user-4.jpg';

import backend_url from "../routes/API_URL";

const API_URL = backend_url['api_url'];

class ProfileMenu extends Component {

	constructor(props) {
		super(props);
		this.state = {
			menu: false,
			modalVisible: false,
			user_password: '',
			email_id: sessionStorage.getItem("umail"),
			id: sessionStorage.getItem("uid"),
			sector: sessionStorage.getItem("sector")

		};
		this.toggle = this.toggle.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.updatePassword = this.updatePassword.bind(this);
		this.switchSector = this.switchSector.bind(this);

	}

	toggle() {
		this.setState(prevState => ({
			menu: !prevState.menu,
			sector: sessionStorage.getItem("sector")
		}));
	}

	toggleModal() {
		if (this.state.ModalVisible == false) {
			this.setState({ ModalVisible: true });
		} else {
			this.setState({ ModalVisible: false });
		}
	}

	switchSector() {
		sessionStorage.setItem("sector", sessionStorage.getItem("sector") == 1 ? 2 : 1);
		
		this.setState({ sector: sessionStorage.getItem("sector") });
		// reload state
		this.props.history.push('/dashboard');

		window.location.href = "/dashboard";
	}

	//Form inputs handler and validator
	handleInput(e) {
		e.preventDefault();
		if (e.target.name === 'user_password') {
			if (e.target.value != '' && e.target.value.length >= 8) {
				this.setState({ UpwordState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ UpwordState: 'has-danger' });
			}
		} else if (e.target.name === 'user_password_confirm') {
			if (e.target.value != '' && e.target.value === this.state.user_password) {
				this.setState({ UpwordConfirmState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ UpwordConfirmState: 'has-danger' });
			}
		}

	}

	//updating password
	updatePassword(event) {
		event.preventDefault()

		const { history } = this.props;

		const id = this.state.id;

		const user = {
			email_id: this.state.email_id,
			user_password: this.state.user_password,

		}

		//this.setState({loading : true});

		//console.log(user);
		//axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
		axios.patch(API_URL + `/users/reset_password/${id}`, user, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
			.then(response => {
				this.setState({ ModalVisible: false });
				swal({
					title: "",
					text: "Password updated successfully!",
					icon: "success",
					button: true,
				})
					.then((sucess) => {
						window.location.reload();
					});
			})
			.catch(error => {
				this.setState({
					ModalVisible: false,
					//loading : false,

				});
				swal({
					title: "",
					text: error.name + " : " + error.message + ". Contact the system administrator for more.",
					icon: "error",
					button: true,
				})
					.then((sucess) => {
						this.setState({ ModalVisible: true });
						//window.location.reload();
					});
			})
	}


	render() {
		const user = sessionStorage.getItem("uname");
		const salute = sessionStorage.getItem("sal");
		const role = sessionStorage.getItem("urole");
		const override_sector = sessionStorage.getItem("override_sector") || 0;
		const sector = this.state.sector == 1 ? "Primary" : "Secondary";
		return (
			<React.Fragment>
				<Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
					<DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
						{
							//<img className="rounded-circle header-profile-user" src={user4} alt="Header Avatar" />
						}
						<i className="mdi mdi-account-circle font-size-17 align-middle mr-1 text-white"></i>
					</DropdownToggle>
					<DropdownMenu right>
						<div className="dropdown-divider"></div>
						<Link
							onClick={(e)=>{e.preventDefault();}}
							to='#'
							className="dropdown-item">
							<i className="mdi mdi-account-circle font-size-17 align-middle mr-1"></i>
							<i>{user}</i>
						</Link>
						<div className="dropdown-divider"></div>
						<Link
							onClick={(e)=>{e.preventDefault();}}
							to='#'
							className="dropdown-item">
							<span>{`${role} (${sector})`}</span>
						</Link>
						<div className="dropdown-divider"></div>
						<Link
							to='/pword_change'
							className="dropdown-item">
							<i className="mdi mdi-account-circle font-size-17 align-middle mr-1"></i>
							<i>Change Password</i>
						</Link>
						{ override_sector == 1 &&
							( <Link
								onClick={() => this.switchSector()}
								to='#'
								className="dropdown-item">
								<i className="mdi mdi-switch font-size-17 align-middle mr-1"></i>
								<i>Switch Sector</i>
							</Link> )
						}
						<div className="dropdown-divider"></div>
						<Link
							to='/logout'
							className="dropdown-item">
							<i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
							<span>Logout</span>
						</Link>
					</DropdownMenu>
				</Dropdown>

				<Modal isOpen={this.state.modalVisible}>
					<Form onSubmit={this.updatePassword} >
						<ModalHeader toggle={this.toggleModal}>
							<center className="justify-content-center">PASSWORD RESET FORM</center>
						</ModalHeader>
						<ModalBody>
							<FormGroup>
								<Row>
									<Col xs='auto' sm={4} md={4} lg={4} xl={4}>
										<Label for="Password">New Password</Label>
									</Col>
									<Col xs='auto' sm={8} md={8} lg={8} xl={8}>
										<Input type="password" name="user_password" id="user_password" invalid={this.state.UpwordState === 'has-danger'} onChange={(e) => { this.handleInput(e); }} placeholder='Enter password' required />
										<FormFeedback invalid>
											The password must be atleast 8 characters. Alpha numeric characters are mostly recommended.
										</FormFeedback>
									</Col>
								</Row>
							</FormGroup>

							<FormGroup>
								<Row>
									<Col xs='auto' sm={4} md={4} lg={4} xl={4}>
										<Label for="ConfirmPassword">Confirm Password</Label>
									</Col>
									<Col xs='auto' sm={8} md={8} lg={8} xl={8}>
										<Input type="password" name="user_password_confirm" id="user_password_confirm" invalid={this.state.UpwordConfirmState === 'has-danger'} onChange={(e) => { this.handleInput(e); }} placeholder='Re-enter password' required />
										<FormFeedback invalid>
											Password Mismatched.
										</FormFeedback>
									</Col>
								</Row>
							</FormGroup>

						</ModalBody>
						<ModalFooter>
							<Button type="submit" color="success">Submit</Button>
							<Button color="danger" onClick={this.toggleModal}>Cancel</Button>
						</ModalFooter>
					</Form>
				</Modal>
			</React.Fragment>
		);
	}
}

export default withRouter(ProfileMenu);


