//const root_url = "https://www.technixlabs.org/api";


//const root_url = "http://backend.technixlabs.org:8001/api";

//const root_url = "https://backend.technixlabs.org/api";

const root_url = "https://siisapi.education.gov.mw/api";

//const root_url = "http://localhost/sms/api";


module.exports = {
    api_url : root_url
}
