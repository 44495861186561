import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete} from '@material-ui/icons';
import swal from 'sweetalert';

import backend_url from "../../../routes/API_URL";

import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";
//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class NESRequirements extends Component {
  constructor(props) {
    super(props);
    this.state = {
	  modalVisible:false,
      EditModalVisible:false,
	  requirement_id:'',
	  requirement_name:'',
	  nes_id:'',
	  nes_level_id:'',
	  RequirementIdState:'',
	  RequirementNameState:'',
	  NesIdState:'',
	  NesLevelIdState:'',
      bearer_auth:window.sessionStorage.getItem('token'),
	  role:window.sessionStorage.getItem('urole'),
	  NESRequirements:[],
	  NESLevels:[],
	  Standards:[],
	  loading: '',
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addNESRequirement = this.addNESRequirement.bind(this);
	this.editNESRequirement = this.editNESRequirement.bind(this);
	this.updateNESRequirement = this.updateNESRequirement.bind(this);
	this.deleteNESRequirement =this.deleteNESRequirement.bind(this);
	;
	
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		 modalVisible:false,
		 requirement_id:'',
	     requirement_name:'',
	     nes_id:'',
	     nes_level_id:'',
	     RequirementIdState:'',
	     RequirementNameState:'',
	     NesIdState:'',
	     NesLevelIdState:'',
		 }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisiblet:true});
    }else{
     this.setState({
		 EditModalVisible:false,
		 requirement_id:'',
	     requirement_name:'',
	     nes_id:'',
	     nes_level_id:'',
	     RequirementIdState:'',
	     RequirementNameState:'',
	     NesIdState:'',
	     NesLevelIdState:'',
		 }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  //Edit Schools
  editNESRequirement(id) {
	
	//this.setState({loading : true});
	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/nes_requirements/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].requirement_id,
		  requirement_name : response.data[0].requirement_name,
		  nes_id:response.data[0].nes_id,
		  nes_level_id:response.data[0].nes_level_id,
		  EditModalVisible:true,
		  //loading: false,

	  })
	}).catch(error => {
		  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
	  })
  }
  
  //Update 
	  updateNESRequirement(event){
		event.preventDefault()

        const { history } = this.props
        const id = this.state.id
		
        const nes_requirement = {
		  requirement_name : this.state.requirement_name,
		  nes_id:this.state.nes_id,
		  nes_level_id:this.state.nes_level_id,
		  
        }
		
		//this.setState({loading : true});
		
        //console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/nes_requirements/update/${id}`, nes_requirement,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
				//this.setState({loading : false});
				
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					EditModalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer: 2500,
				  button: false
				})
				.then((sucess) => {
					Promise.all([axios.get(API_URL + `/nes_requirements`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/national_standards`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_levels`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
						([requirement, standard, level]) => {
							this.setState({
								NESRequirements: requirement.data,
								Standards: standard.data,
								NESLevels: level.data,
								loading: false,

							})
						}
					)
				});
			}else{
				this.setState({
					EditModalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading: false,
				});
				
				if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						this.setState({EditModalVisible:true});
					});
			  }
          }) 
	 }
	 
	 //delete
	deleteNESRequirement(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the nes requirement?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
						confirm: {
						  text: "Delete",
						  value: true,
						  visible: true,
						  className: "",
						  closeModal: true
						},
						cancel: {
						  text: "Cancel",
						  value: false,
						  visible: true,
						  className: "",
						  closeModal: true,
						}
					  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
							//this.setState({loading : true});
							
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/nes_requirements/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								//this.setState({loading : false});
								swal({
								  title: "",
								  text: "The nes requirement record Deleted Successfully!",
								  icon: "success",
								  button: false,
								  timer: 2500
								})
								.then((sucess) => {
									Promise.all([axios.get(API_URL + `/nes_requirements`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/national_standards`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_levels`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
										([requirement, standard, level]) => {
											this.setState({
												NESRequirements: requirement.data,
												Standards: standard.data,
												NESLevels: level.data,
												loading: false,

											})
										}
									)
								});
							}).catch(error => {
								  if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: error.name + ': ' + error.message,
											  icon: "error",
											  button:true
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
							  })
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
  componentDidMount () {
	 
	 this.setState({loading : true});
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 Promise.all([axios.get(API_URL + `/nes_requirements`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/national_standards`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/nes_levels`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([requirement,standard,level])=>{
		 this.setState({
			 NESRequirements:requirement.data,
			 Standards:standard.data,
			 NESLevels:level.data,
			 loading: false,
			 
		 })
	 }
	 ).catch(error => {
			  this.setState({loading : false});
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
  }
	
  //Add user
	 addNESRequirement(event){
		event.preventDefault()

        const { history } = this.props

        const nes_requirement = {
		//   requirement_id: this.state.requirement_id,
		  requirement_name : this.state.requirement_name,
		  nes_id:this.state.nes_id,
		  nes_level_id:this.state.nes_level_id,
		  
        }
		
		//this.setState({loading : true});
        //console.log(user);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/nes_requirements', nes_requirement,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				modalVisible:false, 
				//loading: false,
				});
			//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					modalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer: 2500,
				  button: false
				})
				.then((sucess) => {
					Promise.all([axios.get(API_URL + `/nes_requirements`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/national_standards`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_levels`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
						([requirement, standard, level]) => {
							this.setState({
								NESRequirements: requirement.data,
								Standards: standard.data,
								NESLevels: level.data,
								loading: false,

							})
						}
					)
				});
			}else{
				this.setState({
					modalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				modalVisible:true, 
				//loading: false,
				});
				
				if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: error.name + ': ' + error.message,
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='requirement_id'){
		   if(e.target.value!=''){
			   this.setState({RequirementIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({RequirementIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='requirement_name'){
		   if(e.target.value!=''){
			   this.setState({RequirementNameState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({RequirementNameState :'has-danger'});
		   }
	   
    }else if(e.target.name==='nes_id'){
		   if(e.target.value!=''){
			   this.setState({NesIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({NesIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='nes_level_id'){
		   if(e.target.value!=''){
			   this.setState({NesLevelIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({NesLevelIdState :'has-danger'});
		   }
	   
    }
        
  }

  render() {
    const {NESRequirements, Standards, NESLevels} = this.state;
    const data = {
      columns: [
        { 
          title: 'Requirement Id',
           field: 'requirement_id' 
        },
		{ 
          title: 'Requirement Name',
           field: 'requirement_name' 
        },
		{ 
           title: 'National Standard',
           field: 'nes_id' 
        },
		{ 
           title: 'NES Level',
           field: 'nes_level_id' 
        },
        {
          title: 'Actions',
          field: 'OPTIONS',
		  export : false,
        },
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">nes requirements</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">NES Requirements Management</h3>
                <hr/>
                <Button color="success" hidden={(this.state.role=='System Administrator')?false:true} onClick={this.toggle}> {<Add />} New Requirement</Button>
                <hr/>

                {
                    //Add user modal
					
					/*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
                }
                <Modal isOpen={this.state.modalVisible}>
                        <Form enctype="multipart/form-data" onSubmit={this.addNESRequirement} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">NES REQUIREMENT ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                {/* <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="requirement_id">Level ID&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="number" name="requirement_id" id="requirement_id" invalid={this.state.RequirementIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup> */}
								
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="requirement_name">Requirement Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea"  name="requirement_name" id="requirement_name" invalid={this.state.RequirementNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
								
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_id">NES&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" name="nes_id" id="nes_id" invalid={this.state.NesIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
											<option></option>
											{
											  Standards.map(standard=>(
												  <option value={standard.nes_id} key={standard.nes_id}>{standard.nes_name}</option>
												)
											  )
											}
									  </Input>
									  <FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                
								 <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_level_id">NES Level&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" name="nes_level_id" id="nes_level_id" invalid={this.state.NesLevelIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
											<option></option>
											{
											  NESLevels.map(level=>(
												  <option value={level.nes_level_id} key={level.nes_level_id}>{level.nes_level_name}</option>
												)
											  )
											}
									  </Input>
									  <FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
				 
                  <Table responsive striped size="lg">
				 
                      <MaterialTable
                          title="NES REQUIREMENTS IN MALAWI"
                          columns={data.columns}
                          data={
								NESRequirements.map(requirement=>(	
									{
									  
									  requirement_id : requirement.requirement_id,
									  requirement_name : requirement.requirement_name,
									  nes_id : requirement.nes_name,
									  nes_level_id:requirement.nes_level_name,
									 'OPTIONS': <pre><Link to={`/nes_requirements`} className={(this.state.role=='System Administrator')?"btn":"btn disabled"} onClick={()=>this.editNESRequirement(requirement.requirement_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;
													<Link to={`/nes_requirements`} className={(this.state.role=='System Administrator')?"btn":"btn disabled"} onClick={()=>this.deleteNESRequirement(requirement.requirement_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									}
						  ))}      
                          
						  options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
						  
                        />
						
					</Table>
						

                  {
                    //Edit user record modal
                  }
                  <Modal isOpen={this.state.EditModalVisible}>
                        <Form enctype="multipart/form-data" onSubmit={this.updateNESRequirement} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">SCHOOL EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
									 <Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
								</FormGroup>
								 
								
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="requirement_name">Requirement Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea" value={this.state.requirement_name} name="requirement_name" id="requirement_name" invalid={this.state.RequirementNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
								
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_id">NES&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" value={this.state.nes_id} name="nes_id" id="nes_id" invalid={this.state.NesIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
											<option></option>
											{
											  Standards.map(standard=>(
												  <option value={standard.nes_id} key={standard.nes_id}>{standard.nes_name}</option>
												)
											  )
											}
									  </Input>
									  <FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                
								 <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_level_id">NES Level&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" value={this.state.nes_level_id} name="nes_level_id" id="nes_level_id" invalid={this.state.NesLevelIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
											<option></option>
											{
											  NESLevels.map(level=>(
												  <option value={level.nes_level_id} key={level.nes_level_id}>{level.nes_level_name}</option>
												)
											  )
											}
									  </Input>
									  
									  <FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>	
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default NESRequirements;

