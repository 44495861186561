import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete} from '@material-ui/icons';
import swal from 'sweetalert';

import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";
//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class VisitTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {

      modalVisible:false,
      EditModalVisible:false,
	  visit_type_id:'',
	  visit_type_description:'',
	  nes_category_id :'',
	  VisitTypeIdState:'',
	  VisitTypeNameState:'',
	  NESCategoryIdState:'',
      bearer_auth:window.sessionStorage.getItem('token'),
	  role:window.sessionStorage.getItem('urole'),
	  VisitTypes:[],
	  Categories:[],
	  loading: '',
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addVisitTypes = this.addVisitTypes.bind(this);
	this.editVisitTypes = this.editVisitTypes.bind(this);
	this.updateVisitTypes = this.updateVisitTypes.bind(this);
	this.deleteVisitTypes=this.deleteVisitTypes.bind(this);
	;
	
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		 modalVisible:false,
		 visit_type_id:'',
	     visit_type_description:'',
	     nes_category_id :'',
	     VisitTypeIdState:'',
	     VisitTypeNameState:'',
	     NESCategoryIdState:'',
		 }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisible:true});
    }else{
     this.setState({
		 EditModalVisible:false,
		 visit_type_id:'',
	     visit_type_description:'',
	     nes_category_id :'',
	     VisitTypeIdState:'',
	     VisitTypeNameState:'',
	     NESCategoryIdState:'',
		 }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  //Edit Schools
  editVisitTypes(id) {
	
	//this.setState({loading : true});
	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/visit_types/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].visit_type_id,
		  visit_type_description : response.data[0].visit_type_description,
		  nes_category_id  : response.data[0].nes_category_id ,
		  //division_id : response.data[0].division_id,
		  EditModalVisible:true,
		  //loading: false,
	  })
	}).catch(error => {
		   if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:false
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
	  })
  }
  
  //Update 
	  updateVisitTypes(event){
		event.preventDefault()

        const { history } = this.props
        const id = this.state.id
		
        const visit_type = {
		  visit_type_description: this.state.visit_type_description,
		  nes_category_id : this.state.nes_category_id ,
		  //division_id : this.state.division_id,
		  
        }
		
		//this.setState({loading : true});
        //console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/visit_types/update/${id}`, visit_type,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
                //this.setState({loading : false});
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					EditModalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer:1250
				})
				.then((sucess) => {
					Promise.all([axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_categories`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
						([visit_type, category]) => {
							this.setState({
								VisitTypes: visit_type.data,
								Categories: category.data,
								loading: false,
							})
						}
					)
				});
			}else{
				this.setState({
					EditModalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:false
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading: false,
				});
				if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:false
					})
					.then((sucess) => {
						this.setState({EditModalVisible:true});
					});
			  }
          }) 
	 }
	 
	 //delete
	deleteVisitTypes(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the visit category?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
						confirm: {
						  text: "Delete",
						  value: true,
						  visible: true,
						  className: "",
						  closeModal: true
						},
						cancel: {
						  text: "Cancel",
						  value: false,
						  visible: true,
						  className: "",
						  closeModal: true,
						}
					  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
						    //this.setState({loading : true});
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/visit_types/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								//this.setState({loading : false});
								swal({
								  title: "",
								  text: "The visit type Deleted Successfully!",
								  icon: "success",
								  button:false
								})
								.then((sucess) => {
									Promise.all([axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_categories`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
										([visit_type, category]) => {
											this.setState({
												VisitTypes: visit_type.data,
												Categories: category.data,
												loading: false,
											})
										}
									)
								});
							}).catch(error => {
									if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: error.name + ': ' + error.message,
											  icon: "error",
											  button:false
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
							  })
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
  componentDidMount () {
	 
	 this.setState({loading : true});
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 Promise.all([axios.get(API_URL + `/visit_types`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/nes_categories`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([visit_type, category])=>{
		 this.setState({
			 VisitTypes:visit_type.data,
			 Categories:category.data,
			 loading: false,
		 })
	 }
	 ).catch(error => {
			  this.setState({loading : false});
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:false
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
  }
	
  //Add user
	 addVisitTypes(event){
		event.preventDefault()

        const { history } = this.props

        const visit_type = {
		//   visit_type_id : this.state.visit_type_id,
		  visit_type_description: this.state.visit_type_description,
		  nes_category_id  : this.state.nes_category_id ,
		  //division_id : this.state.division_id,
		  
        }
		
		//this.setState({loading : true});
        //console.log(user);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/visit_types',visit_type,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				modalVisible:false, 
				//loading: false,
				});
			//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					modalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer:1250
				})
				.then((sucess) => {
					Promise.all([axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_categories`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
						([visit_type, category]) => {
							this.setState({
								VisitTypes: visit_type.data,
								Categories: category.data,
								loading: false,
							})
						}
					)
				});
			}else{
				this.setState({
					modalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:false
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				modalVisible:true, 
				//loading: false,
				});
				if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: error.name + ': ' + error.message,
						  icon: "error",
						  button:false
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='visit_type_id'){
		   if(e.target.value!=''){
			   this.setState({VisitTypeIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({VisitTypeIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='visit_type_description'){
		   if(e.target.value!=''){
			   this.setState({VisitTypeNameState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({VisitTypeNameState :'has-danger'});
		   }
	   
    }else if(e.target.name==='nes_category_id '){
		   if(e.target.value!=''){
			   this.setState({NESCategoryIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({NESCategoryIdState :'has-danger'});
		   }
	   
    }
	
        
  }

  render() {
    const {VisitTypes, Categories} = this.state;
    const data = {
      columns: [
        { 
          title: 'Id',
           field: 'visit_type_id' 
        },
		{ 
          title: 'Description',
           field: 'visit_type_description' 
        },
		{ 
           title: 'Nes Category',
           field: 'nes_category_id' 
        },
        
        {
          title: 'Actions',
          field: 'OPTIONS',
		  export : false,
        },
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">Visit TYpe</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">Visit Type Management</h3>
                <hr/>
                <Button color="success" hidden={(this.state.role=='System Administrator')?false:true} onClick={this.toggle}> {<Add />} Visit Type</Button>
                <hr/>

                {
                      //Add user modal
                }
                <Modal isOpen={this.state.modalVisible}>
                        <Form onSubmit={this.addVisitTypes} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">VISIT TYPE ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                {/* <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="visit_type_id">VISIT TYPE ID&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="number" name="visit_type_id" id="visit_type_id" invalid={this.state.VisitTypeIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup> */}
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="visit_type_description">VISIT TYPE Description&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea"  name="visit_type_description" id="visit_type_description" invalid={this.state.VisitTypeNameState==='has-danger'}  onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_category_id ">NES Category&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" name="nes_category_id " id="nes_category_id" invalid={this.state.NESCategoryIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} > 
											<option></option>
                                            {
											  Categories.map(category=>(
												  <option value={category.category_id} key={category.category_id}>{category.category_name}</option>
												)
											  )
											}
									  </Input>
									  <FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
                  
                  <Table responsive striped size="lg">
                      <MaterialTable
                          title="SCHOOL VISIT'S TYPES IN MALAWI"
                          columns={data.columns}
                          data={
								VisitTypes.map(visit_type=>(	
									{
									  
									  visit_type_id : visit_type.visit_type_id,
									  visit_type_description : visit_type.visit_type_description,
									  nes_category_id  : visit_type.category_name,
									 'OPTIONS': <pre><Link to={`/visit_types`} className={(this.state.role=='System Administrator')?"btn":"btn disabled"} onClick={()=>this.editVisitTypes(visit_type.visit_type_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;
																<Link to={`/visit_types`} className={(this.state.role=='System Administrator')?"btn":"btn disabled"} onClick={()=>this.deleteVisitTypes(visit_type.visit_type_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									}
						  ))}      
                          
						  options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
						  
                        />

                  </Table>

                  {
                    //Edit user record modal
                  }
                  <Modal isOpen={this.state.EditModalVisible}>
                        <Form onSubmit={this.updateVisitTypes} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">SCHOOL EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
									 <Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
								</FormGroup>
								  
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="visit_type_description">VISIT TYPE Description&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea" value={this.state.visit_type_description} name="visit_type_description" id="visit_type_description" invalid={this.state.VisitTypeNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_category_id ">NES Category&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select"  value={this.state.nes_category_id } name="nes_category_id " id="nes_category_id" invalid={this.state.NESCategoryIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} > 
											<option></option>
                                            {
											  Categories.map(category=>(
												  <option value={category.category_id} key={category.category_id}>{category.category_name}</option>
												)
											  )
											}
									  </Input>

										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default VisitTypes;
