import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete} from '@material-ui/icons';
import swal from 'sweetalert';

import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";
//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class Zones extends Component {
  constructor(props) {
    super(props);
    this.state = {

      modalVisible:false,
      EditModalVisible:false,
	  zone_id:'',
	  zone_name:'',
	  sector_id:'',
	  district_id :'',
	  division_id :'',
	  ZoneIdState:'',
	  ZoneNameState:'',
	  SectorIdState:'',
	  DistrictIdState:'',
	  DivisionIdState:'',
      bearer_auth:window.sessionStorage.getItem('token'),
	  Zones:[],
	  Sectors:[],
	  Districts:[],
	  Divisions:[],
	  role:window.sessionStorage.getItem("urole"),
	  juridistiction:'',
	  loading : '',
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addZones = this.addZones.bind(this);
	this.editZones = this.editZones.bind(this);
	this.updateZones = this.updateZones.bind(this);
	this.deleteZones =this.deleteZones.bind(this);
	;
	
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		 modalVisible:false,
		 zone_id:'',
	     zone_name:'',
	     sector_id:'',
	     district_id :'',
	     division_id :'',
	     ZoneIdState:'',
	     ZoneNameState:'',
	     SectorIdState:'',
	     DistrictIdState:'',
	     DivisionIdState:'',
		 }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisiblet:true});
    }else{
     this.setState({
		 EditModalVisible:false,
		 zone_id:'',
	     zone_name:'',
	     sector_id:'',
	     district_id :'',
	     division_id :'',
	     ZoneIdState:'',
	     ZoneNameState:'',
	     SectorIdState:'',
	     DistrictIdState:'',
	     DivisionIdState:'',
	  }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  //Edit Zones 
  editZones(id) {
	
	//this.setState({loading : true});
	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/zones/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].zone_id,
		  zone_name : response.data[0].zone_name,
		  sector_id : response.data[0].sector_id,
		  district_id : response.data[0].district_id,
		  //division_id : response.data[0].division_id,
		  EditModalVisible:true,
		  //loading : false,
	  })
	}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
  }
  
  //Update 
	  updateZones(event){
		event.preventDefault()

        const { history } = this.props
        const id = this.state.id
		
        const zone = {
		  zone_name : this.state.zone_name,
		  sector_id : this.state.sector_id,
		  district_id : this.state.district_id,
		  //division_id : this.state.division_id,
		  
        }
        
		//this.setState({loading : true});
		
		//console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/zones/update/${id}`, zone,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				EditModalVisible:false, 
				//loading : false,
				});
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					EditModalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer:1250
				})
				.then((sucess) => {
					window.location.reload();
				});
			}else{
				this.setState({
					EditModalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading : false,
				});
				if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						this.setState({EditModalVisible:true});
					});
			  }
          }) 
	 }
	 
	 //delete
	deleteZones(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the zone?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
						confirm: {
						  text: "Delete",
						  value: true,
						  visible: true,
						  className: "",
						  closeModal: true
						},
						cancel: {
						  text: "Cancel",
						  value: false,
						  visible: true,
						  className: "",
						  closeModal: true,
						}
					  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
							//this.setState({loading : true});
							
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/zones/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								swal({
								  title: "",
								  text: "The zone record Deleted Successfully!",
								  icon: "success",
								  button:true
								})
								.then((sucess) => {
									this.setState({
										//EditModalVisible:true, 
										//loading : false,
										});
									window.location.reload();
								});
							}).catch(error => {
									if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: error.name + ': ' + error.message,
											  icon: "error",
											  button:true
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
							  })
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
  componentDidMount () {
	 
	 this.setState({loading : true});
	 
	 if(this.state.role=='Division Manager'){
		 const div_id = window.sessionStorage.getItem('division');
		 
		  Promise.all([axios.get(API_URL + `/divisions/zone/${div_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/districts`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get( API_URL + `/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get( API_URL + `/divisions/${div_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		 ([zone, sector, district, division,division_data])=>{
			 this.setState({
				 Zones:zone.data,
				 Sectors:sector.data,
				 Districts:district.data,
				 Divisions:division.data,
				 juridistiction:(division_data.data.length>=1)? "EDUCATIONAL ZONES/CLUSTERS IN " + division_data.data[0]['division_name'] + " DIVISION":'NULL DATA ON',
				 loading : false,
			 })
		 }
		 ).catch(error => {
			  this.setState({loading : false});
			  
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
		 
		 
	 }else if(this.state.role=='DEM'){
		 const dist_id = window.sessionStorage.getItem('district');
		 
		  Promise.all([axios.get(API_URL + `/districts/zone/${dist_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/districts`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get( API_URL + `/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get( API_URL + `/districts/${dist_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		 ([zone, sector, district, division,district_data])=>{
			 this.setState({
				 Zones:zone.data,
				 Sectors:sector.data,
				 Districts:district.data,
				 Divisions:division.data,
				 juridistiction:(district_data.data.length>=1)? "EDUCATIONAL ZONES/CLUSTERS IN " + district_data.data[0]['district_name'] + " DISTRICT":'NULL DATA ON',
				 loading : false,
			 })
		 }
		 ).catch(error => {
			  this.setState({loading : false});
			  
				if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
		 
		 
	 }else if(this.state.role=='Ministry HQ' || this.state.role=='Inspector' || this.state.role == 'System Administrator'){
		Promise.all([axios.get(API_URL + `/zones`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/districts`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get( API_URL + `/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		 ([zone, sector, district, division])=>{
			 this.setState({
				 Zones:zone.data,
				 Sectors:sector.data,
				 Districts:district.data,
				 Divisions:division.data,
				 juridistiction:'EDUCATIONAL ZONES IN MALAWI',
				 loading : false,
			 })
		 }
		 ).catch(error => {
			  this.setState({loading : false});
				if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
	 }
	 
	
  }
	
  //Add user
	 addZones(event){
		event.preventDefault()

        const { history } = this.props

        const zone = {
		//   zone_id : this.state.zone_id,
		  zone_name: this.state.zone_name,
		  sector_id : this.state.sector_id,
		  district_id : this.state.district_id,
		  //division_id : this.state.division_id,
		  
        }
		
		//this.setState({loading : true});
        //console.log(user);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/zones', zone,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				modalVisible:false, 
				//loading : false,
				});
			//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					modalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer:1250
				})
				.then((sucess) => {
					window.location.reload();
				});
			}else{
				this.setState({
					modalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				modalVisible:true, 
				//loading : false,
				});
				if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: error.name + ': ' + error.message,
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='zone_id'){
		   if(e.target.value!=''){
			   this.setState({ZoneIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({ZoneIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='zone_name'){
		   if(e.target.value!=''){
			   this.setState({ZoneNameState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({ZoneNameState :'has-danger'});
		   }
	   
    }else if(e.target.name==='sector_id'){
		   if(e.target.value!=''){
			   this.setState({SectorIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({SectorIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='district_id'){
		   if(e.target.value!=''){
			   this.setState({DistrictIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({DistrictIdState :'has-danger'});
		   }
	   
    } else if(e.target.name==='district_name'){
		   if(e.target.value!=''){
			   this.setState({DistrictNameState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({DistrictNameState :'has-danger'});
		   }
	 //Below condition on division_id is currently not nused 
    }else if(e.target.name==='division_id'){
		   if(e.target.value!=''){
			   this.setState({DivisionIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({DivisionIdState :'has-danger'});
		   }
	   
    }
	
        
  }

  render() {
    const {Zones, Sectors, Districts, Divisions} = this.state;
    const data = {
      columns: [
        { 
          title: 'Zone/Cluster Id',
           field: 'zone_id' 
        },
		{ 
          title: 'Zone/Cluster Name',
           field: 'zone_name' 
        },
		{ 
           title: 'Sector Name',
           field: 'sector_name' 
        },
        {
          title: 'District Name',
          field: 'district_name',
        },
		{
          title: 'Division Name',
          field: 'division_name',
        },
        {
          title: 'Actions',
          field: 'OPTIONS',
		  export : false,
        },
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">zones</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">Zone Management</h3>
                <hr/>
                <Button hidden={(this.state.role=='System Administrator')?false:true} color="success" onClick={this.toggle}> {<Add />} New Zone/Cluster</Button>
                <hr/>

                {
                    //Add user modal
					
					/*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
                }
                <Modal isOpen={this.state.modalVisible}>
                        <Form onSubmit={this.addZones} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">ZONE/CLUSTER ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                {/* <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="zone_id">Zone/Cluster ID&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="number" name="zone_id" id="zone_id" invalid={this.state.ZoneIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup> */}
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="zone">Zone/Cluster Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="text"  name="zone_name" id="zone_name" invalid={this.state.ZoneNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="sector">Sector Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" name="sector_id" id="sector_id" invalid={this.state.SectorIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} > 
                                            <option></option>
                                            {
											  Sectors.map(sector=>(
												  <option value={sector.sector_id} key={sector.sector_id}>{sector.sector_name}</option>
												)
											  )
											}
                                        </Input>
										
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="district">District Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" name="district_id" id="district_id" invalid={this.state.DistrictIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} > 
                                            <option></option>
                                            {
											  Districts.map(district=>(
												  <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
												)
											  )
											}
                                        </Input>
										
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
										
									</Col>
                                  </Row>
                                </FormGroup>
								{
									/*
									
									<FormGroup>
									  <Row>
										<Col xs='auto' sm={4} md={4} lg={4} xl={4}>
										  <Label for="division">Division Name</Label>					 
										</Col>
										<Col xs='auto' sm={8} md={8} lg={8} xl={8}>
											<Input type="select" name="division_id" id="division_id" onChange={this.handleInput} > 
												<option></option>
												{
												  Divisions.map(division=>(
													  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
													)
												  )
												}
											</Input>
										</Col>
									  </Row>
									</FormGroup>
									*/
								}
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
                  
                  <Table responsive striped size="lg">
                      <MaterialTable
                          title={this.state.juridistiction.toUpperCase()}
                          columns={data.columns}
                          data={
								Zones.map(zone=>(	
									{
									  
									  zone_id : zone.zone_id,
									  zone_name : zone.zone_name,
									  sector_name : zone.sector_name,
									  district_name : zone.district_name,
									  division_name : zone.division_name,
										'OPTIONS': <pre><Link className={(this.state.role == 'System Administrator') ? "btn" :"btn invisible"} to={`/zones`} onClick={()=>this.editZones(zone.zone_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;
									            <Link  className={(this.state.role=='System Administrator')?"btn":"btn invisible"} to={`/zones`} onClick={()=>this.deleteZones(zone.zone_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									}
						  ))}      
                          
						  options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
						  
                        />

                  </Table>

                  {
                    //Edit user record modal
                  }
                  <Modal isOpen={this.state.EditModalVisible}>
                        <Form onSubmit={this.updateZones} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">ZONE/CLUSTER EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
									 <Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
								</FormGroup>
								  
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="zone_cluster">Zone/Cluster Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="text"  value={this.state.zone_name} name="zone_name" id="zone_name" invalid={this.state.ZoneNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
										
									</Col>
                                  </Row>
                                </FormGroup>
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="sector">Sector Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" value={this.state.sector_id} name="sector_id" id="sector_id" invalid={this.state.SectorIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} > 
                                            <option></option>
                                            {
											  Sectors.map(sector=>(
												  <option value={sector.sector_id} key={sector.sector_id}>{sector.sector_name}</option>
												)
											  )
											}
                                        </Input>
										
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="district">District Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" value={this.state.district_id} name="district_id" id="district_id" invalid={this.state.DistrictIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} > 
                                            <option></option>
                                            {
											  Districts.map(district=>(
												  <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
												)
											  )
											}
                                        </Input>
										
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
										
									</Col>
                                  </Row>
                                </FormGroup>
								{
									/* 
									
									<FormGroup>
									  <Row>
										<Col xs='auto' sm={4} md={4} lg={4} xl={4}>
										  <Label for="division">Division Name</Label>					 
										</Col>
										<Col xs='auto' sm={8} md={8} lg={8} xl={8}>
											<Input type="select" name="division_id" id="division_id" value={this.state.division_id} onChange={this.handleInput} > 
												<option></option>
												{
												  Divisions.map(division=>(
													  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
													)
												  )
												}
											</Input>
										</Col>
									  </Row>
									</FormGroup>
									
									*/
								}
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default Zones;
