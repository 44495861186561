import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete} from '@material-ui/icons';
import swal from 'sweetalert';

import backend_url from "../../../routes/API_URL";

import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";
//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class Divisions extends Component {
  constructor(props) {
    super(props);
    this.state = {

      modalVisible:false,
      EditModalVisible:false,
	  division_id :'',
	  division_name :'',
	  DivisionIdState:'',
	  DivisionNameState:'',
      bearer_auth:window.sessionStorage.getItem('token'),
	  Divisions:[],
	  role:window.sessionStorage.getItem("urole"),
	  loading: '',
	  
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addDivision = this.addDivision.bind(this);
	this.editDivision = this.editDivision.bind(this);
	this.updateDivision = this.updateDivision.bind(this);
	this.deleteDivision =this.deleteDivision.bind(this);
	;
	
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		 modalVisible:false,
		 division_id :'',
	     division_name :'',
	     DivisionIdState:'',
	     DivisionNameState:'',
		 }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisiblet:true});
    }else{
     this.setState({
		 EditModalVisible:false,
		 division_id :'',
		 division_name :'',
		 DivisionIdState:'',
	     DivisionNameState:'',
		 }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  //Edit user 
  editDivision(id) {
	
	//this.setState({loading : true});
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/divisions/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].division_id,
		  division_name : response.data[0].division_name,
		  EditModalVisible:true,
		  //loading: false,
	  })
	}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
  }
  
  //Update 
	  updateDivision(event){
		event.preventDefault()

        const { history } = this.props
        const id = this.state.id
		
        const division = {
		//   division_id : this.state.division_id,
		  division_name : this.state.division_name,
		  
        }
		
		//this.setState({loading : true});
		
        //console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/divisions/update/${id}`, division,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				EditModalVisible:false, 
				//loading: false,
				});
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
				if(response.data['status']=='success'){
					this.setState({
						EditModalVisible:false, 
					//loading : false,
					});
					swal({
					  title: "",
					  text: response.data['message'],
					  icon: response.data['status'],
					  timer:2500,button:false
					})
					.then((sucess) => {
						Promise.all([axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
							([division]) => {
								this.setState({
									Divisions: division.data,
									loading: false,
								})
							}
						)
					});
				}else{
					this.setState({
						EditModalVisible:true, 
					});
					swal({
					  title: "",
					  text: response.data['message'],
					  icon: response.data['status'],
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
				}
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading: false,
				});
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						this.setState({EditModalVisible:true});
					});
			  }
          }) 
	 }
	 
	 //delete
	deleteDivision(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the division?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
						confirm: {
						  text: "Delete",
						  value: true,
						  visible: true,
						  className: "",
						  closeModal: true
						},
						cancel: {
						  text: "Cancel",
						  value: false,
						  visible: true,
						  className: "",
						  closeModal: true,
						}
					  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
							//this.setState({loading : true});
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/divisions/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								//this.setState({loading : false});
								swal({
								  title: "",
								  text: "The division record Deleted Successfully!",
								  icon: "success",
								  button:false,timer:2500
								})
								.then((sucess) => {
									Promise.all([axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
										([division]) => {
											this.setState({
												Divisions: division.data,
												loading: false,
											})
										}
									)
								});
							}).catch(error => {
								  if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: error.name + ': ' + error.message,
											  icon: "error",
											  button:true
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
							  });
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
  componentDidMount () {
	 
	 this.setState({loading : true});
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 Promise.all([axios.get(API_URL + `/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([division])=>{
		 this.setState({
			 Divisions:division.data,
			 loading: false,
		 })
	 }
	 ).catch(error => {
			  this.setState({loading : false, loading: false,});
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
  }
	
  //Add user
	 addDivision(event){
		event.preventDefault()

        const { history } = this.props

        const division = {
		//   division_id : this.state.division_id,
		  division_name : this.state.division_name,
		  
        }
        
		//this.setState({loading : true});
		//console.log(user);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/divisions', division,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				modalVisible:false, 
				//loading: false,
				});
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
				if(response.data['status']=='success'){
					this.setState({
						modalVisible:false, 
					//loading : false,
					});
					swal({
					  title: "",
					  text: response.data['message'],
					  icon: response.data['status'],
					  timer:2500,button:false
					})
					.then((sucess) => {
						Promise.all([axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
							([division]) => {
								this.setState({
									Divisions: division.data,
									loading: false,
								})
							}
						)
					});
				}else{
					this.setState({
						modalVisible:true, 
					});
					swal({
					  title: "",
					  text: response.data['message'],
					  icon: response.data['status'],
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
				}
          })
          .catch(error => {
            this.setState({
				modalVisible:true, 
				//loading: false,
				});
				if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: error.name + ': ' + error.message,
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='division_id'){
		   if(e.target.value!=''){
			   this.setState({DivisionIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({DivisionIdState :'has-danger'});
		   }
	   
    } else if(e.target.name==='division_name'){
		   if(e.target.value!=''){
			   this.setState({DivisionNameState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({DivisionNameState :'has-danger'});
		   }
	   
    }
	
        
  }

  render() {
    const Divisions = this.state.Divisions;
    const data = {
      columns: [
        { 
          title: 'Division Id',
           field: 'division_id' 
        },
        {
          title: 'Division Name',
          field: 'division_name',
        },
        {
          title: 'Actions',
          field: 'OPTIONS',
		  export : false,
        },
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">divisions</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
						
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">Division Management</h3>
                <hr/>
                <Button hidden={(this.state.role=='System Administrator')?false:true} color="success" onClick={this.toggle}> {<Add />} New Division</Button>
                <hr/>

                {
					//Add user modal
					/*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
                }
                <Modal isOpen={this.state.modalVisible}>
                        <Form onSubmit={this.addDivision} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">DIVISION ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                {/* <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="Division ID">Division ID:&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="number" name="division_id" id="division_id" invalid={this.state.DivisionIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
									  <FormFeedback invalid>
										  The field is required.
									 </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup> */}
                                
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="division">Division Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="text"  name="division_name" id="division_name" value={this.state.division_name} invalid={this.state.DivisionNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true}/>
									  <FormFeedback invalid>
										  The field is required.
									 </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
                  
                  <Table responsive striped size="lg">
				  
                      <MaterialTable
                          title="EDUCATIONAL DIVISIONS IN MALAWI"
                          columns={data.columns}
                          data={
								Divisions.map(division=>(	
									{
									  
									  division_id : division.division_id,
									  division_name : division.division_name,
									 'OPTIONS': <pre><Link className={(this.state.role=='System Administrator')?"btn":"btn disabled"} to={`/divisions/`} onClick={()=>this.editDivision(division.division_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;
									            <Link className={(this.state.role=='System Administrator')?"btn":"btn disabled"} to={`/divisions`} onClick={()=>this.deleteDivision(division.division_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									}
						  ))}      
                          
						  options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
						  
                        />

                  </Table>

                  {
                    //Edit user record modal
                  }
                  <Modal isOpen={this.state.EditModalVisible}>
                        <Form onSubmit={this.updateDivision} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">DIVISION EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
									 <Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
								  </FormGroup>
								  {
									  
									  /*
									  <FormGroup>
										  <Row>
											<Col xs='auto' sm={4} md={4} lg={4} xl={4}>
											  <Label for="Division Id">Division Id</Label>					 
											</Col>
											<Col xs='auto' sm={8} md={8} lg={8} xl={8}>
											  <Input value={this.state.id} type="select" name="division_id" id="salutation" onChange={this.handleInput}> 
												  <option></option>
												  <option>1</option>
												  <option>2</option>
												  <option>3</option>
												  <option>4</option>
												  <option>5</option>
												  <option>6</option>
												  <option>7</option>
												  <option>8</option>
												  <option>9</option>
												  <option>10</option>
											  </Input>
											</Col>
										  </Row>
										</FormGroup>
										
									  
									  */
								  }
                                
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="district">Division Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="text"  name="division_name" id="division_name" value={this.state.division_name} invalid={this.state.DivisionNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
									  <FormFeedback invalid>
										  The field is required.
									 </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default Divisions;
